import React, { useState } from 'react';
import './Waitlist.css';
import logo from '../public/pt-logo.png';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

function Waitlist() {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        subscribeNewsletter: false
    });
    const [showNewsletterSuccess, setShowNewsletterSuccess] = useState(false);

    const avatars = [
        'https://api.dicebear.com/7.x/avataaars/svg?seed=Felix',
        'https://api.dicebear.com/7.x/avataaars/svg?seed=John',
        'https://api.dicebear.com/7.x/avataaars/svg?seed=Sarah',
        'https://api.dicebear.com/7.x/avataaars/svg?seed=Mike'
    ];

    const handleSubmit = async () => {
        if (!email) {
            setSubmitStatus({ type: 'error', message: 'Please enter your email' });
            return;
        }

        setIsSubmitting(true);
        setSubmitStatus({ type: '', message: '' });

        try {
            await addDoc(collection(db, 'Waitlistusers'), {
                email,
                timestamp: serverTimestamp()
            });
            
            setEmail('');
            setShowPopup(true);
        } catch (error) {
            console.error('Error adding email:', error);
            setSubmitStatus({ 
                type: 'error', 
                message: 'Something went wrong. Please try again.' 
            });
        }

        setIsSubmitting(false);
    };

    const handleNewsletterSubmit = async () => {
        if (!formData.firstName || !formData.lastName) {
            setSubmitStatus({ type: 'error', message: 'Please fill in all fields' });
            return;
        }

        try {
            await addDoc(collection(db, 'newsletter'), {
                ...formData,
                email,
                timestamp: serverTimestamp()
            });
            
            setShowPopup(false);
            setShowNewsletterSuccess(true);
            
            setTimeout(() => {
                setShowNewsletterSuccess(false);
            }, 3000);
            
        } catch (error) {
            console.error('Error subscribing to newsletter:', error);
            setSubmitStatus({ 
                type: 'error', 
                message: 'Failed to subscribe to newsletter. Please try again.' 
            });
        }
    };

    return (
        <div className="waitlist-container">
            <div className="hero-section">
                <div className="hero-content">
                    <div className="brand-header">
                        <img 
                            src={logo} 
                            alt="Politorium Logo" 
                            className="brand-logo"
                        />
                        <h2 className="brand-name">Politorium</h2>
                    </div>
                    <div className="brand-divider"></div>
                    <h1 className="hero-text">
                        The Social Platform for <span className="underlined">Respectful Political Discourse</span>
                    </h1>
                    <p className="hero-description">
                        Join a community where diverse political views meet constructive dialogue. 
                        Experience politics differently with real conversations that bridge divides 
                        and foster understanding.
                    </p>
                    <div className="email-container">
                        <input 
                            type="email" 
                            placeholder="email@politorium.com" 
                            className="email-input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button 
                            className="join-button"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Joining...' : 'Join Now'}
                        </button>
                    </div>
                    {showPopup && (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <button 
                                    className="close-button"
                                    onClick={() => setShowPopup(false)}
                                >
                                    ×
                                </button>
                                <div className="success-icon">
                                    <svg viewBox="0 0 52 52">
                                        <circle 
                                            className="success-icon-circle"
                                            cx="26" 
                                            cy="26" 
                                            r="25" 
                                        />
                                        <path 
                                            className="success-icon-check"
                                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                        />
                                    </svg>
                                </div>
                                <h2 className="popup-title">Thank you for joining the waitlist!</h2>
                                <div className="popup-form">
                                    <div className="form-group">
                                        <label className="form-label">First Name</label>
                                        <input
                                            type="text"
                                            className="form-input"
                                            value={formData.firstName}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                firstName: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-input"
                                            value={formData.lastName}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                lastName: e.target.value
                                            })}
                                        />
                                    </div>
                                    <label className="newsletter-toggle">
                                        <input
                                            type="checkbox"
                                            checked={formData.subscribeNewsletter}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                subscribeNewsletter: e.target.checked
                                            })}
                                        />
                                        Sign up for PoliTorium newsletter
                                    </label>
                                    <button 
                                        className="submit-button"
                                        onClick={handleNewsletterSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {showNewsletterSuccess && (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <div className="success-icon">
                                    <svg viewBox="0 0 52 52">
                                        <circle 
                                            className="success-icon-circle"
                                            cx="26" 
                                            cy="26" 
                                            r="25" 
                                        />
                                        <path 
                                            className="success-icon-check"
                                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                        />
                                    </svg>
                                </div>
                                <h2 className="popup-title">Successfully signed up for the newsletter!</h2>
                                <p className="popup-description">
                                    Thank you for subscribing. We'll keep you updated with our latest news.
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="community-info">
                        <div className="avatars-group">
                            {avatars.map((avatar, index) => (
                                <img 
                                    key={index}
                                    src={avatar}
                                    alt={`Community member ${index + 1}`}
                                    className="avatar"
                                />
                            ))}
                        </div>
                        <span className="community-text">80+ patriots have already joined the community</span>
                    </div>
                    <div className="features-section">
                        <h2 className="section-title">What Makes Politorium Different</h2>
                        <div className="features-grid">
                            <div className="feature-card">
                                <div className="feature-header">
                                    <div className="feature-icon">📝</div>
                                    <div className="feature-badges">
                                        <span className="feature-badge">Core Feature</span>
                                        <span className="feature-badge">New</span>
                                    </div>
                                </div>
                                <h3 className="feature-title">Content & Discussions</h3>
                                <p className="feature-description">
                                    Share posts, engage in debates, and participate in meaningful political discussions. Our platform ensures content from all perspectives is fairly represented.
                                </p>
                                <ul className="feature-list">
                                    <li className="feature-list-item">AI-powered content balancing</li>
                                    <li className="feature-list-item">Multi-format discussions</li>
                                    <li className="feature-list-item">Real-time engagement</li>
                                </ul>
                                <div className="feature-tags">
                                    <span className="feature-tag">discussions</span>
                                    <span className="feature-tag">fairAlgorithm</span>
                                    <span className="feature-tag">engagement</span>
                                </div>
                            </div>

                            <div className="feature-card">
                                <div className="feature-header">
                                    <div className="feature-icon">🏛️</div>
                                    <div className="feature-badges">
                                        <span className="feature-badge">Community</span>
                                    </div>
                                </div>
                                <h3 className="feature-title">Local Political Engagement</h3>
                                <p className="feature-description">
                                    Connect with your local political ecosystem and participate in discussions about issues that directly affect your community.
                                </p>
                                <ul className="feature-list">
                                    <li className="feature-list-item">Location-based groups</li>
                                    <li className="feature-list-item">Local representative access</li>
                                    <li className="feature-list-item">Community initiatives</li>
                                </ul>
                                <div className="feature-tags">
                                    <span className="feature-tag">localPolitics</span>
                                    <span className="feature-tag">community</span>
                                </div>
                            </div>

                            <div className="feature-card">
                                <div className="feature-header">
                                    <div className="feature-icon">📊</div>
                                    <div className="feature-badges">
                                        <span className="feature-badge">Interactive</span>
                                        <span className="feature-badge">Popular</span>
                                    </div>
                                </div>
                                <h3 className="feature-title">Democratic Tools</h3>
                                <p className="feature-description">
                                    Access a comprehensive suite of democratic tools to make your voice heard on issues that matter.
                                </p>
                                <ul className="feature-list">
                                    <li className="feature-list-item">Live polling system</li>
                                    <li className="feature-list-item">Petition creation & tracking</li>
                                    <li className="feature-list-item">Structured debates</li>
                                </ul>
                                <div className="feature-tags">
                                    <span className="feature-tag">polls</span>
                                    <span className="feature-tag">petitions</span>
                                    <span className="feature-tag">debates</span>
                                </div>
                            </div>

                            <div className="feature-card">
                                <div className="feature-header">
                                    <div className="feature-icon">🏛</div>
                                    <div className="feature-badges">
                                        <span className="feature-badge">Community</span>
                                        <span className="feature-badge">Core Value</span>
                                    </div>
                                </div>
                                <h3 className="feature-title">Respectful Community</h3>
                                <p className="feature-description">
                                    Join a community built on respect, open-mindedness, and constructive dialogue where diverse viewpoints are welcomed.
                                </p>
                                <ul className="feature-list">
                                    <li className="feature-list-item">Reputation system</li>
                                    <li className="feature-list-item">Community guidelines</li>
                                    <li className="feature-list-item">Constructive dialogue tools</li>
                                </ul>
                                <div className="feature-tags">
                                    <span className="feature-tag">respect</span>
                                    <span className="feature-tag">openMinded</span>
                                    <span className="feature-tag">community</span>
                                </div>
                            </div>

                            <div className="feature-card">
                                <div className="feature-header">
                                    <div className="feature-icon">📊</div>
                                    <div className="feature-badges">
                                        <span className="feature-badge">Verified</span>
                                        <span className="feature-badge">Unbiased</span>
                                    </div>
                                </div>
                                <h3 className="feature-title">Verified Information</h3>
                                <p className="feature-description">
                                    Access unbiased, fact-checked political news from multiple perspectives for comprehensive understanding.
                                </p>
                                <ul className="feature-list">
                                    <li className="feature-list-item">Multi-source verification</li>
                                    <li className="feature-list-item">Balanced coverage</li>
                                    <li className="feature-list-item">Real-time fact-checking</li>
                                </ul>
                                <div className="feature-tags">
                                    <span className="feature-tag">news</span>
                                    <span className="feature-tag">factChecked</span>
                                    <span className="feature-tag">unbiased</span>
                                </div>
                            </div>

                            <div className="feature-card">
                                <div className="feature-header">
                                    <div className="feature-icon">📈</div>
                                    <div className="feature-badges">
                                        <span className="feature-badge">Analytics</span>
                                        <span className="feature-badge">Directory</span>
                                    </div>
                                </div>
                                <h3 className="feature-title">Political Directory & Analytics</h3>
                                <p className="feature-description">
                                    Access comprehensive political data and track your engagement with detailed analytics.
                                </p>
                                <ul className="feature-list">
                                    <li className="feature-list-item">Politician profiles</li>
                                    <li className="feature-list-item">Performance tracking</li>
                                    <li className="feature-list-item">Engagement metrics</li>
                                </ul>
                                <div className="feature-tags">
                                    <span className="feature-tag">analytics</span>
                                    <span className="feature-tag">directory</span>
                                    <span className="feature-tag">insights</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="trust-section">
                        <h2 className="section-title">Your Privacy Matters</h2>
                        <div className="trust-grid">
                            <div className="trust-card">
                                <div className="trust-icon">🔒</div>
                                <h3 className="trust-title">Data Protection</h3>
                                <p className="trust-description">
                                    Your personal information is encrypted and securely stored. We never share your data with third parties without your explicit consent.
                                </p>
                            </div>

                            <div className="trust-card">
                                <div className="trust-icon">🛡️</div>
                                <h3 className="trust-title">Content Security</h3>
                                <p className="trust-description">
                                    Advanced moderation tools and AI systems ensure a safe environment for political discourse, free from harassment and hate speech.
                                </p>
                            </div>

                            <div className="trust-card">
                                <div className="trust-icon">✅</div>
                                <h3 className="trust-title">Verified Information</h3>
                                <p className="trust-description">
                                    Our fact-checking system and trusted source verification ensure you receive accurate, unbiased political information.
                                </p>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="footer-content">
                            <div className="footer-brand">
                                <div className="footer-logo">
                                    <img src={logo} alt="Politorium Logo" />
                                    <span className="footer-logo-text">Politorium</span>
                                </div>
                                <p className="footer-description">
                                    The social platform for respectful political discourse.
                                </p>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Waitlist;
