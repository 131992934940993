import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBo9mvVKZt06Pkxo0Zed9YGLaBG1Vh8yLw",
  authDomain: "politorium-64c54.firebaseapp.com",
  projectId: "politorium-64c54",
  storageBucket: "politorium-64c54.firebasestorage.app",
  messagingSenderId: "804090695814",
  appId: "1:804090695814:web:1261e17fd48e7af5694107",
  measurementId: "G-32ZX6BYQN8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db }; 